<template>
  <v-container>
    <v-row>
      <!-- Timeline Column -->
      <v-col cols="12" style="flex: 1">
        <v-card class="grey lighten-5">
          <v-card-title class="grey lighten-2"> Types </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form">
              <v-row
                v-for="routingType in board.routingTypes"
                :key="routingType.id"
              >
                <v-col cols="12" sm="5">
                  <v-text-field
                    label="Naam"
                    :rules="[rules.required]"
                    v-model="routingType.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field
                    label="Korte Naam"
                    :rules="[rules.required]"
                    v-model="routingType.shortName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-checkbox
                    v-model="routingType.isActive"
                    label="In Gebruik"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>

              <v-row class="grey lighten-3">
                <v-col cols="12" sm="5">
                  <v-text-field
                    label="Naam"
                    v-model="newRoutingType.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field
                    label="Korte Naam"
                    v-model="newRoutingType.shortName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-checkbox
                    v-model="newRoutingType.isActive"
                    label="In Gebruik"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-btn width="100%" @click="addRoutingType">
                    <v-icon dark> mdi-plus </v-icon>
                    Voeg toe
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>

              <v-card-actions>
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-btn
                      width="100%"
                      color="primary"
                      @click="saveTypes"
                      :loading="saving"
                    >
                      Opslaan
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- snackbar on save -->
    <v-snackbar
      v-model="saveSnackbar"
      :multi-line="true"
      :color="snackbarSuccess ? 'light-green' : 'red'"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="saveSnackbar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import services from "@/services/services.js";

export default {
  props: {},
  components: {},
  created() {
    this.updateRoutingTypes();
  },
  data() {
    return {
      colorPicker: false,
      saving: false,
      newRoutingType: {
        name: "",
        shortName: "",
        isActive: true,
        id: "00000000-0000-0000-0000-000000000000",
      },
      valid: true,
      rules: {
        required: (value) => !!value || "Verplicht.",
      },
      saveSnackbar: false,
      snackbarSuccess: true,
      snackbarText: "",
    };
  },
  computed: {
    ...mapState(["board"]),
  },
  methods: {
    addRoutingType() {
      if (
        this.newRoutingType.name != "" &&
        this.newRoutingType.shortName != ""
      ) {
        this.board.routingTypes.push(this.newRoutingType);
        const routingType = {
          name: "",
          shortName: "",
          isActive: "",
          id: "00000000-0000-0000-0000-000000000000",
        };

        this.newRoutingType = routingType;
      }
    },
    saveTypes() {
      this.saving = true;
      if (this.valid) {
        this.sendRoutingTypesToApi(this.board.routingTypes);
      } else {
        this.$refs.form.validate();
        this.snackbarSuccess = false;
        this.snackbarText =
          "Gelieve de foutmeldingen eerst op te lossen alvorens op te slaan.";
        this.saveSnackbar = true;
        this.saving = false;
      }
    },
    sendRoutingTypesToApi(routingTypes) {
      // sending job to api
      services
        .saveRoutingTypes(routingTypes)
        .then((response) => {
          this.busyEditting = false;
          // console.log(response);
          if (response.data) {
            this.snackbarSuccess = true;
            this.snackbarText = "Opslaan was succesvol.";
            this.saveSnackbar = true;
            this.updateRoutingTypes();
          }
        })
        .catch((error) => {
          this.snackbarSuccess = false;
          this.snackbarText =
            "Er was een probleem, gelieve opnieuw te proberen. \n" +
            error.response.data.detail;
          this.saveSnackbar = true;
          console.log(error.response.data.title);
          this.savingJob = false;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    updateRoutingTypes() {
      services
        .getRoutingTypes(false)
        .then((response) => {
          this.$store.commit("SET_ROUTINGTYPES", {
            response,
          });
        })
        .catch((error) => {
          this.$store.commit("SAVE_ERROR", { error });
          console.log(error.response.data.title);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
